/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://uf3g3fbydk.execute-api.ap-southeast-2.amazonaws.com/zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://jsdxkhb3n6.execute-api.ap-southeast-2.amazonaws.com/zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://dt5z1qt7gl.execute-api.ap-southeast-2.amazonaws.com/zhiweidev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:149e1c0c-05ba-4c6f-a203-38e7e537f7bb",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_MI8o06y6E",
    "aws_user_pools_web_client_id": "39nkta0ahilajupi364n27e543",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-zhiweidev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-zhiweidev",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
